.amcharts-ForceDirectedLink {
  animation: dash 4s linear;
  animation-iteration-count: infinite;
}

@keyframes dash {
  to {
    stroke-dashoffset: 100;
  }
}

html {
  font-size: 12px;
}

body {
  background-color: #fafafa !important;
}

#root {
  font-family: 'Poppins';
}

.techdocs-reader-page article {
  margin: 0;
  background-color: white !important;
}
